<template>
  <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="sign__content">
                    <form @submit.prevent="unsubscribe" class="sign__form" style="max-width: 600px;" method="POST">
                        <a href="index.php" class="sign__logo">
                            <img src="assets/img/SudaniWhiteLogo-NoShadow.png" alt="" />
                        </a>
						<input type="hidden" name="serviceCode" v-model="serviceCode"/>
              			<input type="hidden" name="token" v-model="token" />
                        <p style="text-align: center;font-family: 'Cairo', sans-serif;font-size: 18px;">هل انت متأكد من انك تريد إلغاء الإشتراك من خدمة نشوف</p>
						<div class="sign__group" style="margin-bottom: 2px;">
                            <button class="sign__btn button">
                                <span v-if="loading" class="px-1 arabickufi">الغاء الاشتراك</span>  &nbsp;
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                <span v-else class="arabickufi">الغاء الاشتراك</span>
                            </button>
                            <!-- <button class="sign__btn" style="margin: 30px 0 0 auto;" >الغاء الاشتراك</button> -->
						</div>
                        <div class="sign__group pointer" style="margin: 0px 0 0 auto;"></div>
                        <a class="sign__btn" style="margin: 30px 0 0 auto;" @click="goBack">رجـــــوع</a>
                    </form>
                    <!-- <div class="sign__group" style="margin-bottom: 2px;">
                        
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useToast } from "vue-toastification";
export default {
  data() {
     return {
       loading: false,
     };
   },
//    mounted() {
//        if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
//           this.$router.push({ path: "/" })
//         }
//     },
    methods: {
        goBack() {
            return this.$router.go(-1)
        },
        async unsubscribe () {
          const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
          this.loading = true;
        await HTTP.post(`SpayUnsubscribe.php?msisdn=`+this.$cookie.getCookie("msisdn")+`&serviceId=2222&opratorID=2`).then((response) => {
          if (response.data.status === 1) {
              toast.success("تم الغاء اشتراكك من منصة نشوف ", { timeout: 2500 });
              this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
              this.$router.push('/')
           } else {
              toast.error("انت لست مشترك في منصة نشوف", { timeout: 2500 });
              this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
              this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>